import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { GridContext } from 'contexts/GridContext';
import { AuthContext } from 'contexts/AuthContext';
import { DropsContext } from 'contexts/DropsContext';
import { PedidosContext } from 'contexts/PedidosContext';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { toastWarning } from 'utils/toast';
import { encryptURL } from 'utils/functions';
import { useModal } from 'components/Modals';
import ResumoModal from 'components/Modals/ResumoModal';
import Container from 'components/Container';
import Header from 'components/Header';
import Card from 'components/Card';
import Grid from 'components/Grid';
import Filter from './filter';
import mock from './mock';

const Pedidos = () => {
  const rotina = 'Pedidos';
  const navigate = useNavigate();
  const { openModal } = useModal();
  const { user } = useContext(AuthContext);
  const { drops } = useContext(DropsContext);
  const { exportPdf, exportXls } = useContext(GridContext);
  const { postDocumentosGenerator, postDocumentos } =
    useContext(DocumentosContext);
  const { pedidos, getPedidos, getLoading } = useContext(PedidosContext);
  const { control, getValues, reset } = useForm({
    defaultValues: pedidos?.filter,
  });

  const loadGrid = (props) => {
    if (props?.replace) {
      reset();
    }
    getPedidos({ ...props, rotina, filter: getValues() });
  };

  const options = [
    {
      name: 'Adicionar Pedido',
      icon: 'add',
      action: () => navigate('/app/Pedidos/Gerar'),
    },
    {
      name: 'Exportar PDF',
      icon: 'file_download',
      action: () =>
        exportPdf({ rotina, filter: getValues(), order: pedidos?.order }),
    },
    {
      name: 'Exportar XLS',
      icon: 'file_download',
      action: () =>
        exportXls({ rotina, filter: getValues(), order: pedidos?.order }),
    },
    {
      name: 'Resumo',
      icon: 'summarize',
      action: () =>
        openModal(
          <ResumoModal
            colunas={pedidos?.colunas}
            onSubmit={(group) =>
              navigate('/app/Resumo', {
                state: { rotina, group, filter: getValues() },
              })
            }
          />
        ),
    },
    {
      name: 'Gerar Nota Fiscal',
      icon: 'post_add',
      action: (documentos) => {
        if (!Boolean(documentos?.length)) {
          return toastWarning('Selecione ao menos um item');
        }
        const data = {
          especie_id: 10,
          documentos: documentos?.map((d) => d?.id),
        };
        postDocumentosGenerator({
          data,
          cb: loadGrid,
        });
      },
    },
    {
      name: 'Liberar Expedição',
      icon: 'local_shipping',
      action: (documentos) => {
        if (!Boolean(documentos?.length)) {
          return toastWarning('Selecione ao menos um pedido');
        }
        if (documentos?.some((a) => a?.aprovacao)) {
          return toastWarning('Selecione apenas pedidos aprovados');
        }
        if (
          documentos?.some(
            (a) =>
              a?.status !== 'PENDENTE' ||
              [35, 36, 37, 38, 39, 40, 41, 42]?.includes(a?.status_id)
          )
        ) {
          return toastWarning('Selecione apenas pedidos pendentes');
        }
        if (user?.integracao?.includes('WEMOV')) {
          const data = documentos?.map((d) => ({
            Registros: [{ documento_id: d?.id, status_id: 13 }],
          }));
          postDocumentos({ data, cb: loadGrid });
        } else {
          const hash = encryptURL(documentos?.map((d) => d?.id));
          navigate(`/app/Pedidos/Expedicao/${hash}`);
        }
      },
    },
  ];
  const rowOptions = [
    {
      name: 'Aprovação necessária',
      icon: 'priority_high',
      color: 'secondary.main',
      show: ({ row }) => Boolean(row?.aprovacao),
    },
    {
      name: 'Detalhes',
      icon: 'visibility',
      action: ({ row }) => navigate(`/app/Documento/${row?.id}`),
    },
  ];

  return (
    <Container>
      <Header rotina={rotina} colunas={pedidos?.colunas} />
      <Card>
        <Grid
          grid={pedidos}
          mock={mock}
          options={options}
          rowOptions={rowOptions}
          control={control}
          checkboxSelection
          loading={getLoading}
          loadGrid={loadGrid}
          activeFilter={(getActiveFilter) => getActiveFilter(getValues())}
          FilterComponent={
            <Filter grid={pedidos} control={control} drops={drops} />
          }
        />
      </Card>
    </Container>
  );
};

export default Pedidos;
