import React from 'react';
import { useForm } from 'react-hook-form';
import { Grid, IconButton } from '@mui/material';
import { QueryStats, Visibility } from '@mui/icons-material';
import {
  dropBoolean,
  dropIndPres,
  dropDivisao,
  dropTipoContrato,
  dropReajusteContrato,
  dropViaTransporte,
  dropIntermedio,
} from 'utils/drops';
import Card from 'components/Card';
import Input from 'components/Input';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import InputMask from 'components/InputMask';
import VirtualDrop from 'components/VirtualDrop';
import api from 'services/api';
import styles from '../styles';
import mock from '../mock';

const Documento = ({
  drops,
  loading,
  onSubmit,
  documento = {},
  disableActions,
}) => {
  const defaultValues = {
    //GERAL
    id: documento?.id,
    especie_id: documento?.especie_id || null,
    natureza_operacao_id: documento?.natureza_operacao_id || null,
    filial_id: documento?.filial_id || null,
    cadastro_id: documento?.cadastro_id || null,
    dtemissao: documento?.dtemissao || null,
    documento: documento?.documento || '',
    contribuinte_id: documento?.contribuinte_id || null,
    usofinal: documento?.usofinal || null,
    indpres: documento?.indpres || null,
    celocal_id: documento?.celocal_id || null,
    //SAIDA
    ped_cliente: documento?.DocumentoSaida?.ped_cliente || '',
    dtatend: documento?.DocumentoSaida?.dtatend || null,
    dtsaida: documento?.DocumentoSaida?.dtsaida || null,
    condicao_pagamento_id:
      documento?.DocumentoSaida?.condicao_pagamento_id || null,
    serie_id: documento?.DocumentoSaida?.serie_id || null,
    numDocumento: documento?.DocumentoSaida?.documento || '',
    vendedor_id: documento?.DocumentoSaida?.vendedor_id || null,
    entidade_endereco_id:
      documento?.DocumentoSaida?.entidade_endereco_id || null,
    divisaotp: documento?.DocumentoSaida?.divisaotp || null,
    divisaopc: documento?.DocumentoSaida?.divisaopc || null,
    //ENTRADA
    dtentrada: documento?.DocumentoEntrada?.dtentrada || null,
    custo: documento?.DocumentoEntrada?.custo || null,
    ped_fornec: documento?.DocumentoEntrada?.ped_fornec,
    //MOEDA
    moeda_id: documento?.DocumentoMoeda?.moeda_id || null,
    cotacao: documento?.DocumentoMoeda?.cotacao || '',
    //EXPORTACAO
    ufembarque_id: documento?.DocumentoExportacao?.ufembarque_id || null,
    locembarque: documento?.DocumentoExportacao?.locembarque || '',
    //IMPORTACAO
    numero: documento?.DocumentoImportacao?.numero || '',
    data: documento?.DocumentoImportacao?.data || null,
    desembaraco_loc: documento?.DocumentoImportacao?.desembaraco_loc || '',
    desembaraco_uf: documento?.DocumentoImportacao?.desembaraco_uf || '',
    desembaraco_dat: documento?.DocumentoImportacao?.desembaraco_dat || null,
    tpviatransp: documento?.DocumentoImportacao?.tpviatransp || null,
    vafrmm: documento?.DocumentoImportacao?.vafrmm || '',
    tpintermedio: documento?.DocumentoImportacao?.tpintermedio || null,
    cnpjadq: documento?.DocumentoImportacao?.cnpjadq || '',
    //CONTRATO
    tipo: documento?.DocumentoContrato?.tipo || null,
    reajuste: documento?.DocumentoContrato?.reajuste || null,
    intervalo: documento?.DocumentoContrato?.intervalo || '',
    //ADICIONAL
    observacao: documento?.observacao || '',
    dadosadc: documento?.dadosadc || '',
  };
  const { control, watch, setValue, handleSubmit } = useForm({ defaultValues });
  const {
    tipoComplemento,
    showEntidade,
    showContribuinte,
    showLocalEstoque,
    showDocumento,
    isSaida,
    isImportacao,
    isExportacao,
    isContrato,
    isPedido,
    isFatura,
  } = mock({
    documento,
  });

  const onChangeEntidade = async (v) => {
    if (v) {
      const params = {
        entidade_id: v?.value,
        tipo: tipoComplemento,
      };
      const { data } = await api.get(`/Cadastros/Entidade/Complemento`, {
        params,
      });
      if (data?.contribuinte_id) {
        setValue('contribuinte_id', data?.contribuinte_id);
      }
      if (data?.usofinal) {
        setValue('usofinal', data?.usofinal);
      }
      if (data?.vendedor_id) {
        setValue('vendedor_id', data?.vendedor_id);
      }
      if (data?.condicao_pagamento_id) {
        setValue('condicao_pagamento_id', data?.condicao_pagamento_id);
      }
      if (data?.divisaotp) {
        setValue('divisaotp', data?.divisaotp);
      }
      if (data?.divisaopc) {
        setValue('divisaopc', data?.divisaopc);
      }
    } else {
      setValue('contribuinte_id', 3);
      setValue('usofinal', 'NAO');
    }
    setValue('entidade_endereco_id', null);
  };

  const onConfirm = (values) => {
    let data = {
      Documento: {
        id: documento?.id,
        users_id: documento?.users_id,
        financeiro_movimento_id: documento?.financeiro_movimento_id,
        conferido: documento?.conferido,
        gerado: documento?.gerado,
        conversao: documento?.conversao,
        natureza_operacao_id: values?.natureza_operacao_id,
        especie_id: values?.especie_id,
        filial_id: values?.filial_id,
        documento: values?.documento,
        cadastro_id: values?.cadastro_id,
        contribuinte_id: values?.contribuinte_id,
        usofinal: values?.usofinal,
        indpres: values?.indpres,
        dtemissao: values?.dtemissao,
        celocal_id: values?.celocal_id,
        observacao: values?.observacao,
        dadosadc: values?.dadosadc,
      },
      Moeda: {
        documento_id: documento?.id,
        moeda_id: values?.moeda_id,
        cotacao: values?.cotacao,
      },
    };
    if (isSaida) {
      data = {
        ...data,
        Saida: {
          documento_id: documento?.id,
          serie_id: values?.serie_id,
          condicao_pagamento_id: values?.condicao_pagamento_id,
          ped_cliente: values?.ped_cliente,
          documento: values?.numDocumento,
          vendedor_id: values?.vendedor_id,
          entidade_endereco_id: values?.entidade_endereco_id,
          dtatend: values?.dtatend,
          dtsaida: values?.dtsaida,
        },
      };
      if (isPedido) {
        data = {
          ...data,
          Saida: {
            ...data?.Saida,
            divisaotp: values?.divisaotp,
            divisaopc: values?.divisaopc,
          },
        };
      }
    }
    if (isExportacao) {
      data = {
        ...data,
        Exportacao: {
          documento_id: documento?.id,
          ufembarque_id: values?.ufembarque_id,
          locembarque: values?.locembarque,
        },
      };
    }
    if (isImportacao) {
      data = {
        ...data,
        Entrada: {
          documento_id: documento?.id,
          dtentrada: values?.dtentrada,
          custo: values?.custo,
          ped_fornec: values?.ped_fornec,
        },
        Importacao: {
          documento_id: documento?.id,
          numero: values?.numero,
          data: values?.data,
          desembaraco_loc: values?.desembaraco_loc,
          desembaraco_uf: values?.desembaraco_uf,
          desembaraco_dat: values?.desembaraco_dat,
          tpviatransp: values?.tpviatransp,
          vafrmm: values?.vafrmm,
          tpintermedio: values?.tpintermedio,
          cnpjadq: values?.cnpjadq,
        },
      };
    }
    if (isContrato) {
      data = {
        ...data,
        Contrato: {
          documento_id: documento?.id,
          tipo: values?.tipo,
          reajuste: values?.reajuste,
          intervalo: values?.intervalo,
        },
      };
    }
    onSubmit(data);
  };

  return (
    <>
      <Card title="Informações Gerais" style={styles?.card}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="especie_id"
              control={control}
              label="Espécie"
              options={drops?.EspecieDocumento}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="natureza_operacao_id"
              control={control}
              label="Natureza da Operação"
              options={drops?.NaturezaOperacao}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <Dropdown
              name="filial_id"
              control={control}
              label="Filial"
              options={drops?.Filial}
            />
          </Grid>
          {showEntidade && (
            <Grid item xs={10}>
              <VirtualDrop
                name="cadastro_id"
                control={control}
                label="Entidade"
                options={drops?.Entidade}
                onValueChange={onChangeEntidade}
                refresh="Entidade"
              />
            </Grid>
          )}
          {showEntidade && (
            <Grid
              item
              xs={2}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <IconButton
                color="primary"
                size="small"
                onClick={() =>
                  window.open(
                    `https://cadastros.eprom2.com.br/app/Entidades/Detalhes/${watch(
                      'cadastro_id'
                    )}`
                  )
                }
                disabled={!Boolean(watch('cadastro_id'))}
              >
                <Visibility fontSize="small" />
              </IconButton>
              <IconButton
                color="primary"
                size="small"
                onClick={() =>
                  window.open(
                    `https://cadastros.eprom2.com.br/app/Entidades/Indicadores/${watch(
                      'cadastro_id'
                    )}`
                  )
                }
                disabled={!Boolean(watch('cadastro_id'))}
              >
                <QueryStats fontSize="small" />
              </IconButton>
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <InputMask
              name="dtemissao"
              control={control}
              label="Data de Emissão"
              type="datetime"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              name="documento"
              control={control}
              label="Nº do Documento"
              type="number"
            />
          </Grid>
          {showContribuinte && (
            <Grid item xs={12} sm={4}>
              <Dropdown
                name="contribuinte_id"
                control={control}
                label="Contribuinte"
                options={drops?.Contribuinte}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={showContribuinte ? 4 : 6}>
            <Dropdown
              name="usofinal"
              control={control}
              label="Uso Final"
              options={dropBoolean}
            />
          </Grid>
          <Grid item xs={12} sm={showContribuinte ? 4 : 6}>
            <Dropdown
              name="indpres"
              control={control}
              label="Indicativo Presencial"
              options={dropIndPres}
            />
          </Grid>
          {showLocalEstoque && (
            <Grid item xs={12}>
              <Dropdown
                name="celocal_id"
                control={control}
                label="Local de Estoque"
                options={drops?.LocalEstoque?.filter(
                  (f) =>
                    !Boolean(f?.filial_id) ||
                    f?.filial_id === watch('filial_id')
                )}
              />
            </Grid>
          )}
        </Grid>
      </Card>
      {isSaida && (
        <Card title="Saída" style={styles?.card}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Input
                name="ped_cliente"
                control={control}
                label="Nº do Pedido do Cliente"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputMask
                name="dtatend"
                control={control}
                label="Data de Atendimento"
                type="date"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputMask
                name="dtsaida"
                control={control}
                label="Data de Saída"
                type="datetime"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Dropdown
                name="condicao_pagamento_id"
                control={control}
                label="Condição de Pagamento"
                options={drops?.CondicaoPagamento?.filter(
                  (c) => c?.modulo !== 'COMPRAS'
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Dropdown
                name="serie_id"
                control={control}
                label="Série"
                options={drops?.Serie}
              />
            </Grid>
            {showDocumento && (
              <Grid item xs={12} sm={6}>
                <Input
                  name="numDocumento"
                  control={control}
                  label="Nº do Documento"
                />
              </Grid>
            )}
            <Grid item xs={12} sm={showDocumento ? 6 : 12}>
              <Dropdown
                name="vendedor_id"
                control={control}
                label="Vendedor"
                options={drops?.EntidadeTipo?.filter(
                  (e) => e?.tipo === 'VENDEDOR' || e?.tipo === 'REPRESENTANTE'
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Dropdown
                name="entidade_endereco_id"
                control={control}
                label="Local de Fornecimento"
                options={drops?.EntidadeEndereco?.filter(
                  (e) => e?.entidade_id === watch('cadastro_id')
                )}
              />
            </Grid>
            {isPedido && (
              <Grid item xs={12} sm={6}>
                <Dropdown
                  name="divisaotp"
                  control={control}
                  label="Divisão do Documento"
                  options={dropDivisao}
                />
              </Grid>
            )}
            {isPedido && (
              <Grid item xs={12} sm={6}>
                <InputMask
                  name="divisaopc"
                  control={control}
                  label="% de Divisão"
                />
              </Grid>
            )}
          </Grid>
        </Card>
      )}
      {isImportacao && (
        <Card title="Entrada" style={styles?.card}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={isPedido && isFatura ? 4 : isPedido || isFatura ? 6 : 12}
            >
              <InputMask
                name="dtentrada"
                control={control}
                label="Data de Entrada"
                type="date"
              />
            </Grid>
            {isFatura && (
              <Grid item xs={12} sm={isPedido ? 4 : 6}>
                <Dropdown
                  name="custo"
                  control={control}
                  label="Custo"
                  options={dropBoolean}
                />
              </Grid>
            )}
            {isPedido && (
              <Grid item xs={12} sm={isFatura ? 4 : 6}>
                <Input
                  name="ped_fornec"
                  control={control}
                  label="Documento de Compra"
                />
              </Grid>
            )}
          </Grid>
        </Card>
      )}
      <Card title="Moeda" style={styles?.card}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="moeda_id"
              control={control}
              label="Moeda"
              options={drops?.Moeda}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputMask
              name="cotacao"
              control={control}
              label="Cotação da Moeda"
            />
          </Grid>
        </Grid>
      </Card>
      {isImportacao && (
        <Card title="Importação" style={styles?.card}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Input
                name="numero"
                control={control}
                label="Número da Importação"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputMask
                name="data"
                control={control}
                label="Data da Importação"
                type="date"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Input
                name="desembaraco_loc"
                control={control}
                label="Local de Desembaraço"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Input
                name="desembaraco_uf"
                control={control}
                label="UF de Desembaraço"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputMask
                name="desembaraco_dat"
                control={control}
                label="Data de Desembaraço"
                type="date"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Dropdown
                name="tpviatransp"
                control={control}
                label="Via de Transporte"
                options={dropViaTransporte}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Dropdown
                name="tpintermedio"
                control={control}
                label="Intermédio"
                options={dropIntermedio}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputMask
                name="vafrmm"
                control={control}
                label="Valor da AFRMM"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                name="cnpjadq"
                control={control}
                label="CNPJ do Adquirinte"
              />
            </Grid>
          </Grid>
        </Card>
      )}
      {isExportacao && (
        <Card title="Exportação" style={styles?.card}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Dropdown
                name="ufembarque_id"
                control={control}
                label="UF Embarque"
                options={drops?.UF}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <Input
                name="locembarque"
                control={control}
                label="Local de Embarque"
              />
            </Grid>
          </Grid>
        </Card>
      )}
      {isContrato && (
        <Card title="Contrato" style={styles?.card}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Dropdown
                name="tipo"
                control={control}
                label="Tipo do Contrato"
                options={dropTipoContrato}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Dropdown
                name="reajuste"
                control={control}
                label="Reajuste"
                options={dropReajusteContrato}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <InputMask
                name="intervalo"
                control={control}
                label="Intervalo (Meses)"
              />
            </Grid>
          </Grid>
        </Card>
      )}
      <Card title="Informações Adicionais" style={styles?.card}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Input
              name="observacao"
              control={control}
              label="Observação"
              multiline
              rows={5}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              name="dadosadc"
              control={control}
              label="Dados Adicionais"
              multiline
              rows={5}
            />
          </Grid>
          {!disableActions && (
            <Grid item xs={12} textAlign="center">
              <Button
                color="primary"
                variant="outlined"
                loading={loading}
                onClick={handleSubmit(onConfirm)}
              >
                Salvar
              </Button>
            </Grid>
          )}
        </Grid>
      </Card>
    </>
  );
};

export default Documento;
