import React, { useContext, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  IconButton,
  Box,
  Checkbox,
  Grid,
  TextField,
  Pagination,
} from '@mui/material';
import { Add, ExpandLess, ExpandMore, Remove } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { decryptURL, paginateArray } from 'utils/functions';
import { AuthContext } from 'contexts/AuthContext';
import { DocumentosContext } from 'contexts/DocumentosContext';
import Container from 'components/Container';
import Header from 'components/Header';
import Button from 'components/Button';
import Card from 'components/Card';
import Loader from 'components/Loader';
import styles from './styles';
import moment from 'moment';

const PAGE_COUNT = 10;

const RenderItem = ({ item, selected = [], handleSelect, handleChange }) => {
  const checked = selected?.some((s) => s === item?.id);

  const onCheck = () => {
    if (checked) {
      handleSelect(selected?.filter((f) => f !== item?.id));
    } else {
      handleSelect([...selected, item?.id]);
    }
  };

  const onChange = (qtd) => {
    const quantidade =
      isNaN(qtd) || qtd < 0
        ? 0
        : qtd > Number(item?.quantidade_max)
        ? Number(item?.quantidade_max)
        : qtd;

    handleChange({ ...item, quantidade });
  };

  return (
    <Box display="flex" alignItems="center" flex={1} my={2}>
      <Checkbox checked={checked} onChange={onCheck} size="small" />
      <Box mx={2} flex={1}>
        <Typography variant="body1" fontWeight="bold">
          {item?.descricao}
        </Typography>
        <Box flex={1} display="flex" flexDirection="column">
          {Boolean(item?.referencia) && (
            <Typography variant="caption">
              Referência: {item?.referencia}
            </Typography>
          )}
          {Boolean(item?.quantidade_ped) && (
            <Typography variant="caption">
              Quantidade: {item?.quantidade_ped || 0} {item?.um || ''}
            </Typography>
          )}
          {Boolean(item?.przent) && (
            <Typography variant="caption">
              Prazo de Entrega: {moment(item?.przent)?.format('DD/MM/YYYY')}
            </Typography>
          )}
        </Box>
      </Box>
      <Box display="flex" flexDirection="column">
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <IconButton
            size="small"
            disabled={!checked || Number(item?.quantidade) <= 0}
            onClick={() => onChange(Number(item?.quantidade) - 1)}
          >
            <Remove fontSize="small" />
          </IconButton>
          <TextField
            type="number"
            variant="standard"
            disabled={!checked}
            InputProps={styles?.inputProps}
            value={item?.quantidade}
            onChange={(v) => onChange(v?.target?.valueAsNumber)}
            onBlur={(v) => onChange(v?.target?.valueAsNumber)}
          />
          <IconButton
            size="small"
            disabled={
              !checked ||
              Number(item?.quantidade) >= Number(item?.quantidade_max)
            }
            onClick={() => onChange(Number(item?.quantidade) + 1)}
          >
            <Add fontSize="small" />
          </IconButton>
        </Box>
        <Typography variant="caption" alignSelf="flex-end">
          Disponível: {item?.saldo || 0} {item?.um || ''}
        </Typography>
      </Box>
    </Box>
  );
};

const RenderPedido = ({
  pedido,
  selected = [],
  handleSelect,
  handleChange,
}) => {
  const [expanded, setExpanded] = useState(false);
  const itens = pedido?.Itens || [];

  const handleIconClick = (event) => {
    event.stopPropagation();
    setExpanded((prev) => !prev);
  };

  const checked = itens?.every((e) => selected?.includes(e?.id));

  const onCheck = () => {
    const ids = itens?.map((m) => m?.id);
    if (checked) {
      handleSelect(selected?.filter((f) => !ids?.includes(f)));
    } else {
      handleSelect([...new Set([...selected, ...ids])]);
    }
  };

  return (
    <Accordion expanded={expanded}>
      <AccordionSummary sx={styles?.accordion}>
        <Box display="flex" alignItems="center" flex={1}>
          <Checkbox checked={checked} onChange={onCheck} size="small" />
          <Box mx={2} flex={1}>
            <Typography variant="body1" fontWeight="bold">
              Pedido {pedido?.documento}
            </Typography>
            <Typography variant="subtitle2" fontWeight="normal">
              {pedido?.nome}
            </Typography>
            {Boolean(pedido?.dtemissao) &&
              moment(pedido?.dtemissao)?.isValid() && (
                <Typography variant="subtitle2" fontWeight="normal">
                  Emitido em{' '}
                  {moment(pedido?.dtemissao)?.format('DD/MM/YYYY [às] HH:mm')}
                </Typography>
              )}
          </Box>
        </Box>
        <Box sx={styles?.iconContainer}>
          <IconButton onClick={handleIconClick}>
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={styles?.dets}>
        {itens?.map((item, i) => (
          <RenderItem
            key={`${i}`}
            item={item}
            selected={selected}
            handleSelect={handleSelect}
            handleChange={handleChange}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

const Expedição = () => {
  const { hash } = useParams();
  const ids = decryptURL(hash);
  const navigate = useNavigate();
  const [pedidos, setPedidos] = useState([]);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(1);
  const { user } = useContext(AuthContext);
  const { postDocumento, postLoading, getExpedicao, getLoading } =
    useContext(DocumentosContext);

  const renderValues = (data) => {
    setPedidos(data);
    setSelected(data?.flatMap((d) => d?.Itens?.map((di) => di?.id)));
    setPage(1);
  };

  useEffect(() => {
    getExpedicao({ params: { ids }, cb: renderValues });
  }, []);

  const handleSelect = (arr) => setSelected(arr);

  const handleChange = (item) => {
    setPedidos(
      pedidos?.map((p) => {
        const Itens = p?.Itens?.map((di) => {
          if (di?.id === item?.id) {
            return { ...di, ...item };
          }
          return di;
        });

        return { ...p, Itens };
      })
    );
  };

  const handleSubmit = () => {
    const Itens = [];
    const Origens = [];
    pedidos?.map((p) => {
      p?.Itens?.map((pi) => {
        if (pi?.quantidade > 0 && selected?.some((s) => s === pi?.id)) {
          if (!Origens?.some((s) => s?.documento_id === p?.id)) {
            Origens?.push({ documento_id: p?.id, status_id: 13 });
          }
          Itens?.push({
            descricao: pi?.descricao,
            quantidade: pi?.quantidade,
            infadc: pi?.infadc,
            przent: pi?.przent,
            Origens: [{ documento_item_org_id: pi?.id }],
          });
        }
      });
    });

    const data = {
      Documento: {
        especie_id: 53,
        natureza_operacao_id: 100,
        filial_id: user?.filial_id,
        users_id: user?.id,
      },
      Itens,
      Origens,
      Registros: [{ status_id: 1 }],
    };

    postDocumento({ data, cb: () => navigate(-1) });
  };

  if (getLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header titulo="Criar Lote de Expedição" />
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {paginateArray(pedidos, page, PAGE_COUNT)?.map((pedido, i) => (
              <RenderPedido
                key={`${i}`}
                pedido={pedido}
                selected={selected}
                handleSelect={handleSelect}
                handleChange={handleChange}
              />
            ))}
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="space-between">
            <Typography fontWeight="bold" flex={1}>
              {selected?.length === 0
                ? 'Nenhum item selecionado'
                : selected?.length === 1
                ? `${selected?.length} item selecionado`
                : `${selected?.length} itens selecionados`}
            </Typography>
            <Pagination
              page={page}
              count={Math.ceil(pedidos?.length / PAGE_COUNT)}
              shape="rounded"
              color="primary"
              onChange={(_, page) => setPage(page)}
            />
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button
              color="primary"
              variant="contained"
              loading={postLoading}
              onClick={handleSubmit}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default Expedição;
