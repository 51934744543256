const styles = {
  inputProps: {
    disableUnderline: true,
    sx: {
      '& .MuiInputBase-root': {
        padding: 0,
        border: 'none',
        fontSize: 'inherit',
        background: 'none',
        boxShadow: 'none',
      },
      '& .MuiInput-underline:before': {
        borderBottom: 'none',
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: 'none',
      },
      '& .MuiInput-underline:after': {
        borderBottom: 'none',
      },
      '& .MuiInputBase-input': {
        padding: 0,
        textAlign: 'center',
        width: '50px',
      },
      '& .MuiInput-input': {
        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
          WebkitAppearance: 'none',
        },
      },
      '& .MuiOutlinedInput-input': {
        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
          WebkitAppearance: 'none',
        },
      },
    },
  },
  accordion: {
    '.MuiAccordionSummary-content': { cursor: 'default' },
  },
  dets: { ml: 5 },
  iconContainer: { alignItems: 'center', display: 'flex' },
};

export default styles;
