import React from 'react';
import { Grid } from '@mui/material';
import { dropStatusDocumento } from 'utils/drops';
import Input from 'components/Input';
import InputMask from 'components/InputMask';
import VirtualDrop from 'components/VirtualDrop';
import Dropdown from 'components/Dropdown';

const Filter = ({ grid, control, drops }) => {
  const checkDisplay = (name) => {
    const column = grid?.colunas?.find((c) => c?.field === name);
    if (column?.filterable) {
      return true;
    }
    return false;
  };

  return (
    <Grid container spacing={2}>
      {checkDisplay('id') && (
        <Grid item xs={12}>
          <Input name="id" control={control} label="ID" type="number" />
        </Grid>
      )}
      {checkDisplay('status') && (
        <Grid item xs={12}>
          <Dropdown
            name="status"
            control={control}
            label="Status"
            options={dropStatusDocumento}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('situacao') && (
        <Grid item xs={12}>
          <Dropdown
            name="situacao"
            control={control}
            label="Situação"
            options={drops?.StatusDocumento}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('marketplace_name') && (
        <Grid item xs={12}>
          <Dropdown
            name="marketplace_name"
            control={control}
            label="Marketplace"
            options={drops?.IntegraAccess}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('dtemissao') && (
        <Grid item xs={6}>
          <InputMask
            name="dtemissao.i"
            control={control}
            label="Data de Emissão Inicial"
            type="date"
          />
        </Grid>
      )}
      {checkDisplay('dtemissao') && (
        <Grid item xs={6}>
          <InputMask
            name="dtemissao.f"
            control={control}
            label="Data de Emissão Final"
            type="date"
          />
        </Grid>
      )}
      {checkDisplay('documento') && (
        <Grid item xs={12}>
          <Input
            name="documento"
            control={control}
            label="Nº do Documento"
            type="number"
          />
        </Grid>
      )}
      {checkDisplay('entidade') && (
        <Grid item xs={12}>
          <VirtualDrop
            name="entidade"
            control={control}
            label="Entidade"
            options={drops?.Entidade}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('vldocto') && (
        <Grid item xs={12}>
          <InputMask name="vldocto" control={control} label="Valor" />
        </Grid>
      )}
      {checkDisplay('przent') && (
        <Grid item xs={6}>
          <InputMask
            name="przent.i"
            control={control}
            label="Prazo de Entrega Inicial"
            type="date"
          />
        </Grid>
      )}
      {checkDisplay('przent') && (
        <Grid item xs={6}>
          <InputMask
            name="przent.f"
            control={control}
            label="Prazo de Entrega Final"
            type="date"
          />
        </Grid>
      )}
      {checkDisplay('destino') && (
        <Grid item xs={12}>
          <Input name="destino" control={control} label="Destino" />
        </Grid>
      )}
      {checkDisplay('documento_nota') && (
        <Grid item xs={12}>
          <Input
            name="documento_nota"
            control={control}
            label="Nº da Nota"
            type="number"
          />
        </Grid>
      )}
      {checkDisplay('vendedor') && (
        <Grid item xs={12}>
          <Dropdown
            name="vendedor"
            control={control}
            label="Vendedor"
            options={drops?.EntidadeTipo?.filter(
              (f) => f?.tipo === 'VENDEDOR' || f?.tipo === 'REPRESENTANTE'
            )}
            multiple
          />
        </Grid>
      )}
      {checkDisplay('forma_pagto') && (
        <Grid item xs={12}>
          <Input
            name="forma_pagto"
            control={control}
            label="Forma de Pagamento"
          />
        </Grid>
      )}
      {checkDisplay('whatsapp') && (
        <Grid item xs={12}>
          <Input name="whatsapp" control={control} label="Contato" />
        </Grid>
      )}
    </Grid>
  );
};

export default Filter;
