import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import {
  Delete,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@mui/icons-material';
import { dropTipoEmitente, dropTipoTransportador } from 'utils/drops';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { useDialog } from 'components/Modals';
import Card from 'components/Card';
import Button from 'components/Button';
import VirtualDrop from 'components/VirtualDrop';
import InputMask from 'components/InputMask';
import Dropdown from 'components/Dropdown';
import styles from '../styles';
import moment from 'moment';

const MDFE = ({
  drops,
  loading,
  onSubmit,
  onDelete,
  documento = {},
  disableActions,
}) => {
  const { openDialog } = useDialog();

  const DocumentoModal = () => {
    const defaultValues = {
      documento_mdfe_id: documento?.id,
      documento_id: null,
    };
    const { control, handleSubmit } = useForm({ defaultValues });
    const { postLoading } = useContext(DocumentosContext);

    const onConfirm = (values) => {
      const data = {
        MDFe: { documento_id: documento?.id, Documentos: [values] },
      };
      onSubmit(data);
    };

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <VirtualDrop
            name="documento_id"
            control={control}
            label="Documento"
            options={drops?.Documento?.filter(
              (f) => f?.status === 'CONCLUIDO' && f?.modelo === 'NFe'
            )}
          />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            color="primary"
            variant="outlined"
            loading={postLoading}
            onClick={handleSubmit(onConfirm)}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    );
  };

  const PercursoModal = ({ percursos = [] }) => {
    const defaultValues = {
      documento_mdfe_id: documento?.id,
      sequencia: percursos?.length + 1,
      motorista_id: null,
      ufdest_id: null,
      ufdesc_id: null,
      saida: null,
      kmsaida: '',
      chegada: null,
      kmchegada: '',
    };
    const { control, handleSubmit } = useForm({ defaultValues });
    const { postLoading } = useContext(DocumentosContext);

    const onConfirm = (values) => {
      const data = {
        MDFe: { documento_id: documento?.id, Percursos: [values] },
      };
      onSubmit(data);
    };

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Dropdown
            name="motorista_id"
            control={control}
            label="Motorista"
            options={drops?.EntidadeTipo?.filter(
              (t) => t?.tipo === 'MOTORISTA'
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <VirtualDrop
            name="ufdest_id"
            control={control}
            label="UF de Destino"
            options={drops?.UF}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <VirtualDrop
            name="ufdesc_id"
            control={control}
            label="UF de Descarregamento"
            options={drops?.UF}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputMask
            name="saida"
            control={control}
            label="Data de Saída"
            type="datetime"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputMask name="kmsaida" control={control} label="KM de Saída" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputMask
            name="chegada"
            control={control}
            label="Data de Chegada"
            type="datetime"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputMask name="kmchegada" control={control} label="KM de Chegada" />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            color="primary"
            variant="outlined"
            loading={postLoading}
            onClick={handleSubmit(onConfirm)}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    );
  };

  const CarregamentoModal = () => {
    const defaultValues = {
      documento_mdfe_id: documento?.id,
      cidade_id: null,
    };
    const { control, handleSubmit } = useForm({ defaultValues });
    const { postLoading } = useContext(DocumentosContext);

    const onConfirm = (values) => {
      const data = {
        MDFe: { documento_id: documento?.id, Carregamentos: [values] },
      };
      onSubmit(data);
    };

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <VirtualDrop
            name="cidade_id"
            control={control}
            label="Cidade"
            options={drops?.Cidade}
          />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            color="primary"
            variant="outlined"
            loading={postLoading}
            onClick={handleSubmit(onConfirm)}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    );
  };

  const Mdfe = () => {
    const defaultValues = {
      documento_id: documento?.id,
      tpemit: documento?.DocumentoMdfe?.tpemit || null,
      tptransp: documento?.DocumentoMdfe?.tptransp || null,
      frota_id: documento?.DocumentoMdfe?.frota_id || null,
      reboque1_id: documento?.DocumentoMdfe?.reboque1_id || null,
      reboque2_id: documento?.DocumentoMdfe?.reboque2_id || null,
    };
    const { control, handleSubmit } = useForm({ defaultValues });

    return (
      <Card title="MDFE" style={styles?.card}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="tpemit"
              control={control}
              label="Tipo de Emitente"
              options={dropTipoEmitente}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="tptransp"
              control={control}
              label="Tipo de Transportador"
              options={dropTipoTransportador}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              name="frota_id"
              control={control}
              label="Frota"
              options={drops?.Frota}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              name="reboque1_id"
              control={control}
              label="Reboque 1"
              options={drops?.Frota}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Dropdown
              name="reboque2_id"
              control={control}
              label="Reboque 2"
              options={drops?.Frota}
            />
          </Grid>
          <Grid item xs={12} textAlign="center">
            {!disableActions && (
              <Button
                color="primary"
                variant="outlined"
                loading={loading}
                onClick={handleSubmit((MDFe) => onSubmit({ MDFe }))}
              >
                Salvar
              </Button>
            )}
          </Grid>
        </Grid>
      </Card>
    );
  };

  const Documentos = () => {
    const rotina = 'DocumentoMdfeDocumento';
    const documentos =
      documento?.DocumentoMdfe?.DocumentoMdfeDocumentos?.map((d) => ({
        id: d?.id,
        dtemissao: d?.Documento?.dtemissao,
        documento: d?.Documento?.documento,
        cliente: d?.Documento?.Entidade?.nome,
        cidade: d?.Documento?.cidade,
        uf: d?.Documento?.uf,
        pesoliq: d?.Documento?.DocumentoTransportes?.find(
          (f) => f?.tipotransp === 'DESPACHO'
        )?.pesoliq,
        pesobrt: d?.Documento?.DocumentoTransportes?.find(
          (f) => f?.tipotransp === 'DESPACHO'
        )?.pesobrt,
      })) || [];
    return (
      <Card title="Documentos" style={styles?.card}>
        <Grid container spacing={2}>
          <Grid item xs={12} textAlign="center">
            <Button
              color="primary"
              variant="outlined"
              loading={loading}
              onClick={() =>
                openDialog(<DocumentoModal />, 'Adicionar Documento')
              }
            >
              Adicionar
            </Button>
          </Grid>
          <Grid item xs={12}>
            <DataGrid
              rows={documentos}
              columns={[
                {
                  field: 'dtemissao',
                  headerName: 'Data de Emissão',
                  flex: 1,
                  sortable: false,
                  valueGetter: ({ value }) =>
                    value && moment(value).format('DD/MM/YYYY'),
                },
                {
                  field: 'documento',
                  headerName: 'Documento',
                  flex: 1,
                  sortable: false,
                },
                {
                  field: 'cliente',
                  headerName: 'Cliente',
                  flex: 4,
                  sortable: false,
                },
                {
                  field: 'cidade',
                  headerName: 'Cidade',
                  flex: 2,
                  valueGetter: ({ row }) => {
                    let str = '';
                    const cidade = row?.cidade || '';
                    const uf = row?.uf || '';
                    str = str + cidade;
                    if (Boolean(cidade) && Boolean(uf)) {
                      str = str + ' - ';
                    }
                    str = str + uf;
                    return str;
                  },
                  sortable: false,
                },
                {
                  field: 'pesoliq',
                  headerName: 'Peso Líquido (KG)',
                  type: 'number',
                  flex: 1,
                  sortable: false,
                },

                {
                  field: 'pesobrt',
                  headerName: 'Peso Bruto (KG)',
                  type: 'number',
                  flex: 1,
                  sortable: false,
                },
                {
                  field: 'actions',
                  type: 'actions',
                  headerName: 'Ações',
                  getActions: ({ id }) => {
                    return [
                      <GridActionsCellItem
                        icon={<Delete />}
                        label="Excluir"
                        onClick={() => onDelete({ rotina, id })}
                      />,
                    ];
                  },
                },
              ]}
              hideFooter
              autoHeight
              density="compact"
              disableSelectionOnClick
              disableColumnMenu
              showCellRightBorder
              showColumnRightBorder
              localeText={{ noRowsLabel: 'Nenhum Registro.' }}
            />
          </Grid>
        </Grid>
      </Card>
    );
  };

  const Percurso = () => {
    const rotina = 'DocumentoMdfePercurso';
    const percursos = documento?.DocumentoMdfe?.DocumentoMdfePercursos || [];

    const onMove = (row, way) => {
      const from = percursos?.findIndex((p) => p?.id === row?.id);
      const to = way === 'up' ? from - 1 : from + 1;
      const cond =
        (way === 'up' && from > 0) ||
        (way === 'down' && from < percursos?.length - 1);

      if (cond) {
        const nextState = [...percursos];
        nextState.splice(from, 1);
        nextState.splice(to, 0, row);
        const data = {
          MDFe: {
            documento_id: documento?.id,
            Percursos: nextState?.map((n, i) => ({ ...n, sequencia: i + 1 })),
          },
        };
        onSubmit(data);
      }
    };

    return (
      <Card title="Percurso" style={styles?.card}>
        <Grid container spacing={2}>
          <Grid item xs={12} textAlign="center">
            <Button
              color="primary"
              variant="outlined"
              loading={loading}
              onClick={() =>
                openDialog(
                  <PercursoModal percursos={percursos} />,
                  'Adicionar Percurso'
                )
              }
            >
              Adicionar
            </Button>
          </Grid>
          <Grid item xs={12}>
            <DataGrid
              rows={percursos}
              columns={[
                {
                  field: 'sequencia',
                  headerName: 'Sequência',
                  type: 'number',
                  flex: 1,
                  sortable: false,
                },
                {
                  field: 'destino',
                  headerName: 'Destino',
                  flex: 3,
                  valueGetter: ({ row }) => {
                    let str = '';
                    const cidade = row?.Cidade?.localidade || '';
                    const uf = row?.Uf?.sigla || '';
                    str = str + cidade;
                    if (Boolean(cidade) && Boolean(uf)) {
                      str = str + ' - ';
                    }
                    str = str + uf;
                    return str;
                  },
                  sortable: false,
                },
                {
                  field: 'saida',
                  headerName: 'Saída',
                  flex: 3,
                  valueGetter: ({ row }) => {
                    let str = '';
                    const saida = row?.saida;
                    const km_saida = row?.kmsaida;
                    if (Boolean(saida)) {
                      str = str + moment(saida).format('DD/MM/YY [às] HH:mm');
                    }
                    if (Boolean(saida) && Boolean(km_saida)) {
                      str = str + ' (';
                    }
                    if (Boolean(km_saida)) {
                      str = str + 'KM ' + km_saida;
                    }
                    if (Boolean(saida) && Boolean(km_saida)) {
                      str = str + ')';
                    }
                    return str;
                  },
                  sortable: false,
                },
                {
                  field: 'chegada',
                  headerName: 'Chegada',
                  flex: 3,
                  valueGetter: ({ row }) => {
                    let str = '';
                    const chegada = row?.chegada;
                    const km_chegada = row?.kmchegada;
                    if (Boolean(chegada)) {
                      str = str + moment(chegada).format('DD/MM/YY [às] HH:mm');
                    }
                    if (Boolean(chegada) && Boolean(km_chegada)) {
                      str = str + ' (';
                    }
                    if (Boolean(km_chegada)) {
                      str = str + 'KM ' + km_chegada;
                    }
                    if (Boolean(chegada) && Boolean(km_chegada)) {
                      str = str + ')';
                    }
                    return str;
                  },
                  sortable: false,
                },
                {
                  field: 'actions',
                  type: 'actions',
                  flex: 1,
                  headerName: 'Ações',
                  getActions: ({ id, row }) => {
                    return [
                      <GridActionsCellItem
                        icon={<KeyboardArrowUp />}
                        label="Mover para cima"
                        disabled={row?.sequencia === 1}
                        onClick={() => onMove(row, 'up')}
                      />,
                      <GridActionsCellItem
                        icon={<KeyboardArrowDown />}
                        label="Mover para baixo"
                        disabled={row?.sequencia === percursos?.length}
                        onClick={() => onMove(row, 'down')}
                      />,
                      <GridActionsCellItem
                        icon={<Delete />}
                        label="Excluir"
                        onClick={() => onDelete({ rotina, id })}
                      />,
                    ];
                  },
                },
              ]}
              hideFooter
              autoHeight
              density="compact"
              disableSelectionOnClick
              disableColumnMenu
              showCellRightBorder
              showColumnRightBorder
              localeText={{ noRowsLabel: 'Nenhum Registro.' }}
            />
          </Grid>
        </Grid>
      </Card>
    );
  };

  const Carregamentos = () => {
    const rotina = 'DocumentoMdfeCarregamento';
    const carregamentos =
      documento?.DocumentoMdfe?.DocumentoMdfeCarregamentos || [];

    return (
      <Card title="Carregamentos" style={styles?.card}>
        <Grid container spacing={2}>
          <Grid item xs={12} textAlign="center">
            <Button
              color="primary"
              variant="outlined"
              loading={loading}
              onClick={() =>
                openDialog(<CarregamentoModal />, 'Adicionar Carregamento')
              }
            >
              Adicionar
            </Button>
          </Grid>
          <Grid item xs={12}>
            <DataGrid
              rows={carregamentos}
              columns={[
                {
                  field: 'cidade',
                  headerName: 'Cidade',
                  flex: 1,
                  sortable: false,
                },
                {
                  field: 'actions',
                  type: 'actions',
                  flex: 0,
                  headerName: 'Ações',
                  getActions: ({ id }) => {
                    return [
                      <GridActionsCellItem
                        icon={<Delete />}
                        label="Excluir"
                        onClick={() => onDelete({ rotina, id })}
                      />,
                    ];
                  },
                },
              ]}
              hideFooter
              autoHeight
              density="compact"
              disableSelectionOnClick
              disableColumnMenu
              showCellRightBorder
              showColumnRightBorder
              localeText={{ noRowsLabel: 'Nenhum Registro.' }}
            />
          </Grid>
        </Grid>
      </Card>
    );
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Mdfe />
      </Grid>
      <Grid item xs={12}>
        <Documentos />
      </Grid>
      <Grid item xs={12}>
        <Percurso />
      </Grid>
      <Grid item xs={12}>
        <Carregamentos />
      </Grid>
    </Grid>
  );
};

export default MDFE;
