import React from 'react';
import { Outlet, Route, Routes } from 'react-router';
import App from 'views/App';
import Resumo from 'views/Resumo';
import Configurações from 'views/Configurações';
import User from 'views/User';
import Caixa from 'views/Caixa';
import Orçamentos from 'views/Orçamentos';
import OrçamentosGerar from 'views/Orçamentos/Gerar';
import Pedidos from 'views/Pedidos';
import PedidosGerar from 'views/Pedidos/Gerar';
import PedidosExpedição from 'views/Pedidos/Expedição';
import Notas from 'views/Notas';
import NotasEtiquetas from 'views/Notas/Etiquetas';
import NotasGerar from 'views/Notas/Gerar';
import NotasGerarMDFE from 'views/Notas/GerarMDFE';
import Contratos from 'views/Contratos';
import ContratosGerar from 'views/Contratos/Gerar';
import Entregas from 'views/Entregas';
import RemessasVendaExterna from 'views/Remessas/VendaExterna';
import RemessasVendaExternaItens from 'views/Remessas/VendaExternaItens';
import RemessasIndustrialização from 'views/Remessas/Industrialização';
import RemessasDemonstração from 'views/Remessas/Demonstração';
import RemessasExposiçãoFeira from 'views/Remessas/ExposiçãoFeira';
import RemessasConsertoReparo from 'views/Remessas/ConsertoReparo';
import RemessasComodato from 'views/Remessas/Comodato';
import RemessasTroca from 'views/Remessas/Troca';
import RemessasGarantia from 'views/Remessas/Garantia';
import RemessasGerar from 'views/Remessas/Gerar';
import RemessasVenda from 'views/Remessas/Venda';
import RemessasBonus from 'views/Remessas/Bonus';
import RemessasRetorno from 'views/Remessas/Retorno';
import RemessasResumo from 'views/Remessas/Resumo';
// import RemessasConsignar from 'views/Remessas/Consignar';
import Garantias from 'views/Garantias';
import GarantiasGerar from 'views/Garantias/Gerar';
import GarantiasResumo from 'views/Garantias/Resumo';
import Documento from 'views/Documento';
import DocumentoItem from 'views/Documento/Item';
import DocumentoVinculo from 'views/Documento/Vinculo';
import DocumentoDevolução from 'views/Documento/Devolução';
import DocumentoAprovações from 'views/Documento/Aprovações';
//RELATÓRIOS
import Relatórios from 'views/Relatórios';
import RelatóriosFiltros from 'views/Relatórios/Filtros';
//OUTLETS
import OutletDocumento from './Outlets/Documento';

const RoutesTree = ({ user }) => (
  <Routes>
    <Route path="" element={<App />} />
    <Route path="Resumo" element={<Resumo />} />
    <Route path="Configuracoes" element={<Configurações />} />
    <Route path="User" element={<User />} />
    <Route path="Caixa" element={<Outlet />}>
      <Route path="" element={<Caixa />} />
      <Route path=":type/:id" element={<Caixa />} />
    </Route>
    <Route path="Relatorios" element={<Outlet />}>
      <Route path="" element={<Relatórios />} />
      <Route path="Filtros" element={<RelatóriosFiltros />} />
    </Route>
    <Route path="Orcamentos" element={<Outlet />}>
      <Route path="" element={<Orçamentos />} />
      <Route path="Gerar" element={<Outlet />}>
        <Route path="" element={<OrçamentosGerar />} />
        <Route path=":documento_id" element={<OrçamentosGerar />} />
      </Route>
    </Route>
    <Route path="Pedidos" element={<Outlet />}>
      <Route path="" element={<Pedidos />} />
      <Route path="Gerar" element={<Outlet />}>
        <Route path="" element={<PedidosGerar />} />
        <Route path=":documento_id" element={<PedidosGerar />} />
      </Route>
      <Route path="Expedicao/:hash" element={<PedidosExpedição />} />
    </Route>
    <Route path="Notas" element={<Outlet />}>
      <Route path="" element={<Notas />} />
      <Route path="Etiquetas" element={<NotasEtiquetas />} />
      <Route path="Gerar" element={<Outlet />}>
        <Route path="NF" element={<NotasGerar />} />
        <Route path="NF/:documento_id" element={<NotasGerar />} />
        <Route path="Fatura" element={<NotasGerar />} />
        <Route path="Fatura/:documento_id" element={<NotasGerar />} />
        <Route path="Cupom" element={<NotasGerar />} />
        <Route path="Cupom/:documento_id" element={<NotasGerar />} />
        <Route path="MDFE" element={<NotasGerarMDFE />} />
      </Route>
    </Route>
    <Route path="Contratos" element={<Outlet />}>
      <Route path="" element={<Contratos />} />
      <Route path="Gerar" element={<Outlet />}>
        <Route path="Contrato" element={<ContratosGerar />} />
        <Route path="Contrato/:documento_id" element={<ContratosGerar />} />
        <Route path="Orcamento" element={<ContratosGerar />} />
        <Route path="Orcamento/:documento_id" element={<ContratosGerar />} />
      </Route>
    </Route>
    <Route path="Entregas" element={<Outlet />}>
      <Route path="" element={<Entregas />} />
    </Route>
    <Route path="Remessas" element={<Outlet />}>
      <Route path="VendaExterna" element={<Outlet />}>
        <Route
          path=""
          element={
            user?.nivel === 'VENDAS' ? (
              <RemessasVendaExternaItens />
            ) : (
              <RemessasVendaExterna />
            )
          }
        />
      </Route>
      <Route path="Industrializacao" element={<RemessasIndustrialização />} />
      <Route path="Demonstracao" element={<RemessasDemonstração />} />
      <Route path="ExposicaoFeira" element={<RemessasExposiçãoFeira />} />
      <Route path="ConsertoReparo" element={<RemessasConsertoReparo />} />
      <Route path="Comodato" element={<RemessasComodato />} />
      <Route path="Troca" element={<RemessasTroca />} />
      <Route path="Garantia" element={<RemessasGarantia />} />
      <Route
        path="Gerar/:especie_id?/:natureza_operacao_id?/:itens?/:documento_id?"
        element={<RemessasGerar />}
      />
      <Route path="Retorno/:id" element={<RemessasRetorno />} />
      <Route path="Resumo/:id" element={<RemessasResumo />} />
      <Route path="Venda/:hash" element={<RemessasVenda />} />
      <Route path="Bonus/:hash" element={<RemessasBonus />} />
      {/*<Route path="Consignar" element={<RemessasConsignar />} /> */}
    </Route>
    <Route path="Garantias" element={<Outlet />}>
      <Route path="" element={<Garantias />} />
      <Route path="Resumo" element={<GarantiasResumo />} />
      <Route path="Gerar" element={<Outlet />}>
        <Route path="" element={<GarantiasGerar />} />
        <Route path=":hash" element={<GarantiasGerar />} />
      </Route>
    </Route>
    <Route path="Documento" element={<OutletDocumento />}>
      <Route path=":id" element={<Documento />} />
      <Route path="Item" element={<DocumentoItem />} />
      <Route path="Vinculo/:hash" element={<DocumentoVinculo />} />
      <Route path="Aprovacoes" element={<DocumentoAprovações />} />
      <Route path="Devolucao/:documento_id" element={<DocumentoDevolução />} />
    </Route>
  </Routes>
);

export default RoutesTree;
